<template>
    <NuxtLayout name="default">
        <GameMenu name="Pet Simulator X" image="/images/games/icons/psx.webp" :links="links" />
        <NuxtLayout name="ads">
            <slot />
        </NuxtLayout>
    </NuxtLayout>
</template>

<script setup lang="ts">
const links = [
    { title: 'Items', to: '/psx/items' },
    { title: 'Trade Calculator', to: '/psx/calculate' },
    { title: 'Trade Ads', to: '/psx/ads' },
    { title: 'Value Changes', to: '/psx/value-changes' }
]

useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Pet Simulator X | RoValue` : 'Pet Simulator X - RoValue'
    }
})
</script>
